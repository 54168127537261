@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  /* margin: 0;
  padding: 0; */
  background-color: #ffffff;
  overflow-x: hidden;
}

.home-img {
  background-image: url("../Assets/Img/desktop-home.jpg");
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 
  padding-top: 2.5rem;
}

.logo {
  width: 178px;
  height: 51px;
  margin-left: 2.7rem;
}

.logo-header {
  display: flex;
  justify-content: space-between;
}

.home-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%; 
}

.home-font {
  font-weight: 500;
  font-size: 80px;
  line-height: 120px;
  letter-spacing: 2%;
  color: white;
  margin-top: 90px;
  text-align: center;
  animation: fadeInUp 1s ease-in-out 0.10s forwards;
}

.elegant-font {
  font-weight: 275;
}

.horizontal-line {
  border: 1px solid #ffffff;
  width: 678px;
  margin: auto;
}

.title-para {
  font-weight: 200;
  color: white;
  font-size: 26px;
  line-height: 40px;
  letter-spacing: 0%;
  margin-left: 25%;
  margin-right: 24%;
  text-align: center;
  animation: fadeInUp 1s ease-in-out 0.14s forwards;
}

.home-btn {
  background: linear-gradient(#7cccbf, #4098c0);
  font-weight: 600;
  font-size: 20px;
  color: white;
  padding: 20px 40px;
  border-radius: 192px;
  margin: auto;
  /* margin-top: 5rem; */
  line-height: 27.26px;
  letter-spacing: 2%;
  animation: fadeInUp 1s ease-in-out 0.16s forwards;
}

.home-btn:hover {
  background: linear-gradient(140deg, #ffffff, #4098c0);
  color: #002c5f;
}

.img-arrow {
  width: 50px;
  height: 50px;
  margin-left: 2rem;
  margin-top: -7rem;

}

/* .arrow-div {
  width: 50px;
  height: 10px;
}

.arrow {
  opacity: 0;
  position: absolute;
      left: 3%;
      top: 50%;
      margin-top: 23%;
  transform-origin: 50% 50%;
  transform: translate3d(-50%, -50%, 0);
}

.arrow-first {
  animation: arrow-movement 2s ease-in-out infinite;
}
.arrow-second {
  animation: arrow-movement 2s 1s ease-in-out infinite;
}

.arrow:before,
.arrow:after {
  background: #ffffff;
  content: '';
  display: block;
  height: 4px; 
  position: absolute;
      top: 0;
      left: 0;
  width: 40px;
}

.arrow:before {
  transform: rotate(45deg) translateX(-23%);
  transform-origin: top left;
}

.arrow:after {
  transform: rotate(-45deg) translateX(23%);
  transform-origin: top right;
}

@keyframes arrow-movement {
  0% { 
      opacity: 0;
      top: 45%;
  }
  70% {
      opacity: 1;
  }
  100% { 
      opacity: 0;
  }
} */

/* Downarrow  */
/* .indicator {
  position: relative;
  width: 50px;
  height: 50px;
  background: #ccc;
  transform: rotate(45deg);
}

.indicator span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
  border: none;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  animation: animate 1s linear infinite;
}

.indicator span :nth-child(1) {
  top: -30px;
  left: -30px;
  animation-delay: 0s;
}

.indicator span :nth-child(2) {
  top: -15px;
  left: -15px;
  animation-delay: 0.2s;
}

.indicator span :nth-child(3) {
  top: 0px;
  left: 0px;
  animation-delay: 0.4s;
}

.indicator span :nth-child(4) {
  top: 15px;
  left: 15px;
  animation-delay: 0.6s;
}

.indicator span :nth-child(5) {
  top: 30px;
  left: 30px;
  animation-delay: 0.8s;
}

@keyframes animate {
  0% {
    border-color: #fff;
    transform: translate(0, 0);
  }
  20% {
    border-color: #fff;
    transform: translate(15px, 15px);
  }
  20.1%,
  100% {
    border-color: #222;
  }
} */

/* .contact-btn:hover {
  background-color: #1a7564;
} */

/* .fade-in-text {
  transition: opacity 4s ease;
} */

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px); /* Add a slight upward movement */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 600px) {
  .home-img {
    background-image: url("../Assets/Img/mobile-home.jpg");
    width: 100%;
  }

  .home-box {
    justify-content:flex-start;
  }

  .logo {
    width: 110px;
    height: 30px;
    margin-left: 2rem;
  }

  .home-font {
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 2%;
    left: 25px;
    margin-top: 3rem;
    margin-bottom: 20px;
    margin-left: 9%;
    width: 321px;
    height: 147px;
  }

  .horizontal-line {
    display: none;
  }

  .title-para {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 2%;
    margin-left: 9%;
    margin-right: 8%;
    margin-top: -1rem !important;
  }

  .home-btn {
    font-size: 15px;
    padding: 11px 20px;
    line-height: 20.15px;
    margin-top: 3rem !important;
  }

  .img-arrow {
    display: none;
  }


}


@media only screen and (min-width: 601px) and (max-width: 1023px) {
  
}


@media screen and (max-width: 768px) {
}
