@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* .corner {
  border-radius: 35px;
} */

.services-box {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.serv-font {
  color: white;
  font-size: 50px;
  line-height: 45px;
  font-weight: 300;
  padding: 2rem;
  text-align: center;

}

.web-box {
  background-color: #7cccbf;
  flex: 1;
}

.web-img {
  display: flex;
  width: 170px;
  opacity: 0.55;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin: auto;

}

.web1 {
  width: 400px;
}

.digital-box {
  background-color: #4098c0;
  flex: 1;

}

.digital-img {
  display: flex;
  width: 180px;
  justify-content: center;
  align-items: center;
  gap: 60px;
  opacity: 0.65;
  margin: auto;

}

.digital3 {
  width: 145px;
}

.branding-box {
  background-color: #002c5f;
  flex: 1;
}

.branding-img {
  display: flex;
  width: 160px;
  justify-content: center;
  align-items: center;
  opacity: 0.45;
  gap: 50px;
  margin: auto;

}

.web-content {
  color: #ffffff;
  font-size: 26px;
  font-weight: 300;
  line-height: 43.5px;
  text-align: center;
  position: absolute;
  opacity: 0;
  margin-top: -15rem;
  padding: 3rem;
  margin-left: 4rem;
  margin-right: 4rem;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.digital-content {
  color: #ffffff;
  font-size: 26px;
  font-weight: 300;
  line-height: 43.5px;
  text-align: center;
  position: absolute;
  opacity: 0;
  margin-top: -15rem;
  padding: 3rem;
  margin-left: 4rem;
  margin-right: 4rem;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.branding-content {
  color: #ffffff;
  font-size: 26px;
  font-weight: 300;
  line-height: 43.5px;
  text-align: center;
  position: absolute;
  opacity: 0;
  margin-top: -15rem;
  padding: 3rem;
  margin-left: 4rem;
  margin-right: 4rem;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}


.web-box:hover .web-content {
  opacity: 1;
}

.web-box:hover .learn-btn {
  opacity: 1;
}

.web-box:hover .serv-font {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.web-box:hover .web-img img {
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out;
}

.digital-box:hover .digital-content {
  opacity: 1;
}

.digital-box:hover .learn-btn {
  opacity: 1;
}

.digital-box:hover .serv-font {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.digital-box:hover .digital-img img {
  opacity: 0.4;
  transition: opacity 0.3s ease-in-out;
}

.branding-box:hover .branding-content {
  opacity: 1;
}

.branding-box:hover .learn-btn {
  opacity: 1;
}

.branding-box:hover .serv-font {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.branding-box:hover .branding-img img {
  opacity: 0.4;
  transition: opacity 0.3s ease-in-out;
}

.web-box:hover .web-content {
  transform: translateX(0); /* Slide content to the left on hover */
}


/* .started {
  background: linear-gradient(#0c7189, #002c5f);
  width: 1443px;
  height: 110px;
}

.started-btn {
  font-family: Poppins;
  background: linear-gradient(#7cccbf, #4098c0);
  font-weight: 500;
  font-size: 20px;
  color: white;
  padding: 20px 40px;
  border-radius: 192px;
  margin-left: 41%;
  margin-top: 100px;
} */

.web-para {
  font-family: Poppins;
  font-size: 29px;
  font-weight: 600;
  margin-top: 3%;
  line-height: 43.5px;
  text-align: center;
  margin-left: 207px;
  margin-right: 200px;
}

.learn-btn {
  background: linear-gradient(#7cccbf, #4098c0);
  font-weight: 600;
  font-size: 20px;
  color: white;
  padding: 10px 30px;
  border-radius: 192px;
  border: 1px solid #ffffff;
  float: right;
  margin-right: 2rem;
  margin-top: -4rem;
  opacity: 0;
  /* z-index: 10; */
  position: relative;

}

.learn-btn:hover {
  background: linear-gradient(140deg, #ffffff, #4098c0);
  color: #002c5f;
}

.slide-in-right {
  animation: slideInRight 0.5s ease-in-out forwards;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}





/* 
.anim750 {
  transition: all 750ms ease-in-out;
}

#Awesome {
  position: relative;
  width: 180px;
  height: 180px;
  margin: 0 auto;

  backface-visibility: hidden;
}

#Awesome .sticky {
  transform: rotate(45deg);
}

#Awesome:hover .sticky {
  transform: rotate(10deg);
}

#Awesome .sticky {
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  height: 180px;
}

#Awesome .reveal .circle {
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.15);

  font-family: "helvetica neue", arial;
  font-weight: 200;
  line-height: 140px;
  text-align: center;

  cursor: pointer;
}

#Awesome .reveal .circle {
  background: #fafafa;
}

#Awesome .circle_wrapper {
  position: absolute;
  width: 180px;
  height: 180px;
  left: 0px;
  top: 0px;
  overflow: hidden;
}

#Awesome .circle {
  position: absolute;
  width: 140px;
  height: 140px;
  margin: 20px;

  border-radius: 999px;
}

#Awesome .back {
  height: 10px;
  top: 30px;
}

#Awesome:hover .back {
  height: 90px;
  top: 110px;
}

#Awesome .back .circle {
  margin-top: -130px;
  background-color: #fbec3f;

  background-image: -webkit-linear-gradient(
    bottom,
    rgba(251, 236, 63, 0),
    rgba(255, 255, 255, 0.8)
  );
}

#Awesome:hover .back .circle {
  margin-top: -50px;
}

#Awesome .front {
  height: 150px;
  bottom: 0;
  top: auto;

  -webkit-box-shadow: 0 -140px 20px -140px rgba(0, 0, 0, 0.3);
}

#Awesome:hover .front {
  height: 70px;

  -webkit-box-shadow: 0 -60px 10px -60px rgba(0, 0, 0, 0.1);
}

#Awesome .front .circle {
  margin-top: -10px;
  background: #fbec3f;

  background-image: -webkit-linear-gradient(
    bottom,
    rgba(251, 236, 63, 0) 75%,
    #f7bb37 95%
  );
  background-image: -moz-linear-gradient(
    bottom,
    rgba(251, 236, 63, 0) 75%,
    #f7bb37 95%
  );
  background-image: linear-gradient(
    bottom,
    rgba(251, 236, 63, 0) 75%,
    #f7bb37 95%
  );
}

#Awesome h4 {
  font-family: "helvetica neue", arial;
  font-weight: 200;
  text-align: center;
  position: absolute;
  width: 180px;
  height: 140px;
  line-height: 140px;

  transition: opacity 50ms linear 400ms;
}

#Awesome:hover h4 {
  opacity: 0;

  transition: opacity 50ms linear 300ms;
}

#Awesome:hover .front .circle {
  margin-top: -90px;
  background-color: #e2d439;
  background-position: 0 100px;
} */

@media only screen and (max-width: 600px) {
  .serv-font {
    font-size: 30px;
    line-height: 45px;
    padding: 1rem;
    opacity: 1;
  }

  .services-box {
    height: auto;
  }

  .web-box {
    flex: 0 0 auto;
    width: 100%;
    height: 250px;
  }

  .digital-box {
    flex: 0 0 auto;
   
  }

  .branding-box {
    flex: 0 0 auto;
    
  }

  .started {
    display: none;
  }

  .web-img {
    width: 53px;
    gap: 20px;
    margin-top: 5rem;
  }

  .digital-img {
    width: 53px;
    gap: 20px;
    margin-top: 7rem;
    /* margin-bottom: 2rem; */

  }

  .branding-img {
    width: 53px;
    gap: 20px;
    margin-top: 7rem;
  }

  .web-content {
    opacity: 1;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding: 0;
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: -8.5rem;
  }

  .digital-content {
    opacity: 1;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-top: -10.5rem;
    padding: 0;
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .branding-content {
    opacity: 1;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-top: -10.5rem;
    padding: 0;
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .learn-btn {
    opacity: 1;
    font-size: 10px;
    padding: 5px 15px;
    color: #002C5F;
    margin-top: 0.5rem;
    margin-right: 9.5rem;
    margin-bottom: 1rem;
  }

  .web-box:hover .serv-font {
    opacity: 1;
  }
  
  .web-box:hover .web-img img {
    opacity: 1;
  }

  .digital-box:hover .serv-font {
    opacity: 1;
  }
  
  .digital-box:hover .digital-img img {
    opacity: 1;
  }

  .branding-box:hover .serv-font {
    opacity: 1;
  }
  
  .branding-box:hover .branding-img img {
    opacity: 1;
  }
  
}
