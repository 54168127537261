.about-main {
  display: flex;
  flex-direction: column;
}

.about-box1 {
  background: linear-gradient(to right, #0c7189, #002c5f);
  color: #ffffff;
  text-align: center;
  padding: 4.5rem;
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 2rem;
}

.about-box2 {
  background-color: #0c7189;
  color: #ffffff;
  padding: 4.5rem;
  font-size: 27px;
  font-weight: 200;
  text-align: center;
  margin-top: -2.2rem;
}

.card-box {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.about-btn {
  background: linear-gradient(#7cccbf, #4098c0);
  font-weight: 500;
  font-size: 20px;
  color: white;
  padding: 20px 40px;
  border-radius: 192px;
  line-height: 27.26px;
  letter-spacing: 2%;
  position: absolute;
  margin: auto;
  align-self: center;
  margin-top: 10.5rem;

}

.about-btn:hover {
  background: linear-gradient(140deg, #ffffff, #4098c0);
  color: #002c5f;
}

.card-text {
  font-size: 22px;
  color: #ffffff;
  font-weight: 300;
  line-height: 37.5px;
  padding: 1.2rem;
  /* text-align: center; */
}

.card-title {
  font-size: 50px;
  font-weight: 500;
  color: #ffffff;
}

.card {
  position: relative;
  width: 370px;
  height: 390px;
  background: #002c5f;
  border-radius: 0px 10px 10px 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  perspective: 100em;
  margin: 20px;
  box-shadow: 30px 40px 40px rgba(0, 0, 0, 0.2);
}
.cover {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* background: #002c5f; */
  /* background: linear-gradient(#1c1c1c, #050505);
  mix-blend-mode: plus-lighter; */
  background: #0c8dac;
  transform-style: preserve-3d;
  transform-origin: left;
  transition: transform 1s;
  border-radius: 0px 10px 10px 0px;
  font-size: 30px;
  font-weight: bold;
}
.card:hover .cover {
  transform: rotateY(-120deg);
}
.coverFront,
.coverBack {
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  /* background: linear-gradient(#7cccbf, #0c7189); */
}
.coverFront {
  text-align: center;
  border-radius: 0px 10px 10px 0px;
  color: #333;
}
.coverBack {
  border-radius: 10px 0px 0px 10px;
  transform: rotateY(180deg);
}

.style-text {
  padding: 20px;
  text-align: center;
  font-size: 12px;
}

.bookmark-icon {
  width: 40px;
  height: 52px;
  float: left;
  margin-top: -1rem;
  margin-left: 1rem;
  
}

.mobile-style {
  display: contents;
}

@media only screen and (max-width: 600px) {
  .about-box1 {
    height: 185px;
    font-size: 15px;
    line-height: 22.5px;
    margin-left: -1rem;
  }

  .about-box2 {
    font-size: 14px;
    line-height: 22.5px;
    margin-left: -2rem;
  }

  .box2-font {
    margin-left: -1rem;
    margin-right: -2rem;
  }

  .about-btn {
    font-size: 15px;
    padding: 10px 20px;
    border-radius: 192px;
    line-height: 20.15px;
    margin-top: 10.2rem;
    margin-bottom: 2rem;
  
  }

  .card-title {
    font-size: 20px;
  }

  .card-text {
    font-size: 10px;
    line-height: 15px;
    margin-left: -1rem;
    margin-right: -1rem;

  }

  .card-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2rem;
  }

  .card {
    width: 156.8px;
    height: 164.57px;
  }

  .bookmark-icon {
    width: 30px;
    margin-left: 0.5rem; 
  }

  .card-box {
    align-items: center;
  }

  .mobile-style {
    display: flex;
    margin-left: 2rem;

  }
}
