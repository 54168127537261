.footer-img {
  width: 100%;
  height: 450px;
  background-repeat: no-repeat;
  background-image: url("../Assets/Img/desktop-footer.jpg");
  background-size: cover;
  
}

.footer-main {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.footer-content {
  position: absolute;
}

.footer-content h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  color: white;
  margin-left: 19%;
  margin-right: 40rem;
  margin-top: 2rem;

}

.footer-logo {
  width: 170px;
  height: 48.57px;
  margin-left: 19%;
  margin-top: 2.5rem;
}

.email-btn {
  margin-top: -4.3rem;
  margin-left: 19%;
  color: #14183e;
}

.email-box {
  width: 372px;
  height: 68px;
  border: 1px;
  border-radius: 192px;
  margin-right: 2rem;
  border: none;
  padding: 1rem;
}

.email-box::placeholder {
  color: #14183e;
  /* opacity: 0.5; */
  padding-left: 1px;
  font-size: 18px;
  font-weight: 500;
}

.footer-btn {
  background: linear-gradient(#7cccbf, #4098c0);
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  padding: 20px 45px;
  border-radius: 192px;
  margin: auto;
  margin-top: 5rem;
  line-height: 30px;
  letter-spacing: 2%;
}

.footer-btn:hover {
  background: linear-gradient(140deg, #ffffff, #4098c0);
  color: #002c5f;
}

.icons {
  display: flex;
  margin-top: 2rem;
  margin-left: 19%;
}

.insta {
  width: 44.86px;
  margin-right: 1.5rem;
}

.linkedin {
  width: 44.86px;
}

.mail-text {
  font-size: 21px;
  color: #ffffff;
  font-weight: 275;
  margin-left: 1.5rem;
  margin-top: 0.5rem;
}

.links {
  margin-top: -3rem;
  color: #ffffff;
  margin-left: 19%;
  margin-top: 1.5rem;
}

.links a {
  font-weight: 275;
  font-size: 21px;
}


@media only screen and (max-width: 600px) {
  .footer-img {
    background-image: url("../Assets/Img/mobile-footer.jpg");
    width: 100%;
    background-size: cover;
    height: 203px;
  }

  .footer-content h1 {
    font-size: 10px;
    line-height: 12px;
    margin-right: 5rem;
    margin-bottom: -9rem;
    margin-top: 1rem;
    margin-left: 5rem;
    font-weight: 200;
    text-align: center;
  }

  .footer-logo {
    width: 94px;
    height: 28px;
    margin-left: 9rem;
    margin-top: 1rem;
  }

  .email-box {
    width: 121.2px;
    height: 24.36px;
    padding: 5px 10px;
    margin-top: 2rem;

  }
  
  .footer-btn {
    font-size: 9px;
    padding: 5px 10px;
    line-height: 13.5px;

  }

  .mail-text {
    font-size: 10px;
    margin-top: 1rem;
    margin-left: -6rem;
  }

  .links a {
    font-size: 10px;
    color: #ffffff;
  }

  .insta {
    width: 24.52px;
    margin-right: 1rem;
    margin-top: 2.2rem;
    margin-left: 5rem;
  }
  
  .linkedin {
    width: 24.06px;
    margin-top: 2.2rem;
  }
  
  .email-box::placeholder {
    font-size: 6px;
    /* display: none; */
  }

  .email-btn {
    margin-top: 5rem;
  }

  .icons {
    margin-top: -0.2rem;
    
  }

  .links {
    margin-top: 0rem;
    margin-left: 7.5rem;
  }

  .footer-main {
    justify-content: center;
  }
  

}