.contact-container {
  background-color: #ffffff;
  width: 100%;
  height: 100vh;
  padding: 4rem;

}

.form-container {
  background: linear-gradient(#0C7189, #002C5F);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 10px 10px 14px #002C5F;

}

.contact-content {
  display: flex;

}

.vertical-line {
  width: 2px; 
  /* height: 550px;  */
  background-color: #ffffff; 
  border: none;
  margin: 3rem;
}

.form-title {
  font-size: 25px;
  color: #ffffff;
  font-weight: 600;
  line-height: 37.5px;
  margin-bottom: 4rem;  
  text-align: center;
}

.contact-logo {
    width: 178px;
    height: 51px;
    margin-left: 0rem;
}

.back-arrow {
  width: 40px;
  float: right;
  margin-top: -3rem;
 
}

.img-title {
  font-size: 28px;
  color: white;
  font-weight: 500;
  line-height: 42px;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.contact-title {
  font-size: 25px;
  color: #002C5F;
  font-weight: 600;
  line-height: 37.5px;
  text-align: center;
}

.info-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
}

.contact-icons {
  display: flex;
  position: fixed;
  bottom: 6rem;
  left: 6rem;
  padding: 20px;

}

.sm\:grid-cols-2 {
  /* margin-right: -15rem; */
}

.sm\:col-span-2 { 
  grid-column: span 0 / span 0;
}

.form-text {
  color: #ffffff;
  margin: 4%;
  font-size: 15px;
  border-bottom: 2px solid #ffffff;
  background-color: transparent;
  /* width: 220px; */
}

.form-text::placeholder {
  color: #ffffff;
  font-size: 15px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.contact-btn {
  background: linear-gradient(#7cccbf, #4098c0);
  font-weight: 600;
  font-size: 18px;
  color: #002c5f;
  padding: 15px 35px;
  border-radius: 192px;
  line-height: 27.26px;
  letter-spacing: 2%;
  margin: 0 auto;
  width: fit-content;
  display: block;
}

.contact-btn:hover {
  background: linear-gradient(140deg, #ffffff, #4098c0);
  color: #002c5f;
}

.form-field {
  color: #00246b;
}

.info-font {
  padding: 10%;
  font-size: 30px;
  color: #00246b;
  font-family: Helvetica;
  margin-left: 22%;
}

.email-icon {
  width: 24.87px;
  height: 24.87px;
}

.phone-icon {
  width: 18.46px;
  height: 18.46px;
}

.location-icon {
  width: 17.1px;
  height: 21.76px;
}

.info-section {
  flex: 3;
}

.form-section {
  flex: 6;
}

@media only screen and (max-width: 600px) { 

  .contact-container {
    height: 200%;
    padding: 2rem;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
  }

  .contact-content {
    display: flex;
    flex-direction: column;
  }
  
  .vertical-line {

  border-bottom: 1px solid #ffffff; 
  width: 100%; 
  height: 1px;
  margin: 20px auto; 
  border: none;

  }

  .form-title {
    font-size: 15px;
    margin: auto;
    text-align: center;
    line-height: 20px;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }


  .form-text {
    font-size: 10px;
    width: 220px;
    margin: auto;
  }

  .form-field {
    margin-left: 2rem;
  }

  .contact-btn {
  font-size: 15px;
  margin-top: 2rem;
  }

  .contact-logo {
    width: 120px;
    height: 35px;
    margin-left: 0rem;
  }

  .back-arrow {
    width: 28px;
    margin-right: 0rem;
    margin-top: -1.8rem;
  }

  .img-title {
    font-size: 15px;
    margin-top: 0rem;
  }

  .phone-icon {
    margin-left: -8.5rem;
  }

  .email-icon {
    margin-left: -3.1rem;
  }

  .info-text {
    font-size: 12px;
  }

  .location-icon {
    margin-left: 0rem;
  }

  .contact-icons {
    position: static;
    margin-left: -6rem;
    margin-top: -20rem;
  }

  .grid.sm\:grid-cols-2.gap-10 {
    margin-left: 1rem;
    width: 230px;
  }

  .phone-info {
    text-align: left;
  }
  
}

@media only screen and (min-width: 601px) and (max-width: 1023px) { 


}

@media only screen and (min-width: 1024px) and (max-width: 1300px) { 

}